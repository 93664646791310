import { motion } from 'framer-motion';
import React from 'react';
import { CaretUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { grayScale } from '../constants/colors';

type SortingType = 'asc' | 'desc' | false;

function TableHeaderCellCarrot({ sortingType }: { sortingType?: 'asc' | 'desc' | false }) {
    return (
        <div
            className={classNames(
                'inline-flex items-center justify-center ease-in-out duration-300 transition-transform',
                {
                    'text-current rotate-180 group-hover:scale-0': sortingType === 'asc',
                    'text-current rotate-0 group-hover:rotate-180': sortingType === 'desc',
                    '!text-transparent group-hover:!text-current': sortingType === false,
                },
            )}
        >
            <CaretUp className="ml-[2px] inline-block" />
        </div>
    );
}

const CellButton = React.forwardRef(
    (
        {
            children,
            sortingType,
            iconLeft,
            ...rest
        }: {
            children: string | JSX.Element;
            iconLeft?: JSX.Element;
            // rightIcon?: JSX.Element;
            sortingType: SortingType;
            hasFocus?: boolean;
        } & React.ButtonHTMLAttributes<HTMLButtonElement>,
        ref: React.LegacyRef<HTMLButtonElement>,
    ) => (
        <button type="button" {...rest} ref={ref}>
            {children}
            {iconLeft}
            <TableHeaderCellCarrot sortingType={sortingType} />
        </button>
    ),
);

const MAP: Record<string, SortingType> = {
    false: 'desc',
    desc: 'asc',
    asc: false,
};
const handleCellClickType = (type: SortingType) => MAP[`${type}`];

export { TableHeaderCellCarrot, CellButton, handleCellClickType };
export type { SortingType };
