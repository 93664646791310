import { Skeleton, Table, TableProps } from '@mantine/core';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import Color from 'color';
import { useViewportSize } from '@mantine/hooks';
import { BareCard } from './Card';
import NoDataCard from './noDataCard';
import { handleCellClickType, SortingType } from './tableHeaderCellCarret';
import TableHeaderCell from './tableHeaderCell';
import { colorsScheme } from '../constants/colors';
import useColorScheme from '../hooks/useColorScheme';
import Pagination from './Pagination';

interface StyledTableProps extends TableProps {
    headers?: JSX.Element[] | JSX.Element;
    body: JSX.Element | JSX.Element[];
    maxHeight?: number | string;
    minHeight?: number | string;
    loading?: boolean;
    dataCondition?: boolean;
    maxWidth?: number | string;
    headerClassName?: string;
    spaceportBareCardClassName?: string;
    bodyClassName?: string;
    hideScroll?: boolean;
    justTable?: boolean;
    noDataText?: string;
    loader?: JSX.Element;
}

const MAX_HEIGHT = 500;
const MIN_HEIGHT = 200;

const TableComp = ({
    body = [],
    headers,
    maxHeight = MAX_HEIGHT,
    className,
    minHeight = MIN_HEIGHT,
    maxWidth,
    loading,
    dataCondition,
    headerClassName,
    hideScroll,
    noDataText,
    loader,
    spaceportBareCardClassName,
    bodyClassName,
    ...rest
}: StyledTableProps) => {
    const { colorScheme } = useColorScheme();
    return (
        <Table
            className={classNames(
                'text-sm capitalize text-center mb-auto !border-border-color bg-columbia-blue-v2-50',
                className,
            )}
            {...rest}
            classNames={{
                tr: 'text-sm capitalize text-center !border-primary/10',
                td: 'text-sm capitalize text-center !border-primary/10',
                tbody: 'text-sm capitalize text-center !border-primary/10',
                th: '!px-3 !py-2.5',
            }}
            styles={{
                ...rest.styles,
                table: {
                    ...(rest.styles?.['table'] ?? {}),
                },
            }}
            stickyHeader={
                typeof rest.stickyHeader !== 'undefined'
                    ? rest.stickyHeader
                    : typeof headers !== 'undefined'
            }
            style={{ ...rest.style, maxHeight }}
            stripedColor={Color(
                `rgba(${colorsScheme[colorScheme]['shadow']}, ${colorsScheme[colorScheme]['shadow-default-opacity']})`,
            ).hexa()}
        >
            {typeof headers !== 'undefined' && (
                <Table.Thead
                    className={classNames(
                        '!backdrop-blur-sm !bg-[rgba(0,0,0,0.03)] -top-0 text-navy-30',
                        headerClassName,
                    )}
                >
                    {Array.isArray(headers) ? (
                        <Table.Tr className="mb-2 whitespace-pre-wrap border-inherit pb-2 text-neutral-400 hover:text-secondary">
                            {headers.map(key => key)}
                        </Table.Tr>
                    ) : (
                        headers
                    )}
                </Table.Thead>
            )}
            {Array.isArray(body) ? <Table.Tbody>{body.map(row => row)}</Table.Tbody> : body}
        </Table>
    );
};

function StyledTable({
    body = [],
    headers,
    maxHeight = 500,
    className,
    minHeight = 200,
    maxWidth,
    loading,
    dataCondition,
    headerClassName,
    hideScroll,
    noDataText,
    loader,
    spaceportBareCardClassName,
    bodyClassName,
    justTable,
    ...rest
}: StyledTableProps) {
    if (loading) {
        return (
            <div
                className="mb-1 flex w-full items-center justify-center"
                style={{ maxHeight, minHeight, maxWidth }}
            >
                {loader ? (
                    <div
                        className="flex size-full items-center justify-center align-middle"
                        style={{ maxHeight, minHeight }}
                    >
                        {loader}
                    </div>
                ) : (
                    <Skeleton className="size-full" style={{ maxHeight, minHeight }} />
                )}
            </div>
        );
    }
    if (dataCondition) {
        return (
            <div
                className="mb-1 flex w-full items-center justify-center"
                style={{ maxHeight, minHeight, maxWidth }}
            >
                <NoDataCard>{noDataText || 'No Data Found'}</NoDataCard>
            </div>
        );
    }
    if (justTable)
        return (
            <div
                className={classNames(
                    'flex justify-start items-center text-sm h-fit capitalize mb-1 max-w-full w-full !overflow-auto !p-0',
                    { hideScroll },
                    spaceportBareCardClassName,
                )}
                style={{ maxHeight, minHeight, maxWidth }}
            >
                <TableComp
                    body={body}
                    headers={headers}
                    maxHeight={maxHeight}
                    className={className}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    loading={loading}
                    dataCondition={dataCondition}
                    headerClassName={headerClassName}
                    hideScroll={hideScroll}
                    noDataText={noDataText}
                    loader={loader}
                    spaceportBareCardClassName={spaceportBareCardClassName}
                    bodyClassName={bodyClassName}
                    {...rest}
                />
            </div>
        );
    return (
        <BareCard
            className={classNames(
                'flex justify-start items-center text-sm h-fit capitalize mb-1 max-w-full w-full !overflow-auto !p-0',
                { hideScroll },
                spaceportBareCardClassName,
            )}
            style={{ maxHeight, minHeight, maxWidth }}
        >
            <TableComp
                body={body}
                headers={headers}
                maxHeight={maxHeight}
                className={className}
                minHeight={minHeight}
                maxWidth={maxWidth}
                loading={loading}
                dataCondition={dataCondition}
                headerClassName={headerClassName}
                hideScroll={hideScroll}
                noDataText={noDataText}
                loader={loader}
                spaceportBareCardClassName={spaceportBareCardClassName}
                bodyClassName={bodyClassName}
                {...rest}
            />
        </BareCard>
    );
}

interface Header<T> {
    value: string | number;
    label?: string;
    get?(row: T): string | number;
}

function PreBuildStyledTable<T = string>({
    header,
    data,
    onRowClick,
    defaultSortingColumn,
    withoutSorting,
    notSortableColumns,
    withoutPagination,
    paginationProps,
    ...rest
}: {
    onRowClick: (props: { index: number; rowData: T }) => void;
    data: T[];
    header: Partial<Record<keyof T, string | Header<T>>>;
    defaultSortingColumn?: keyof T;
    withoutSorting?: boolean;
    notSortableColumns?: (keyof T)[];
    withoutPagination?: boolean;
    paginationProps?: {
        rowsPerPage?: number;
        rowHeight?: number;
    };
} & Omit<StyledTableProps, 'body' | 'headers'>) {
    const [page, setPage] = useState(1);
    const { width: viewPortWidth, height: viewPortHeight } = useViewportSize();

    const [tableHeaderHasFocus, setTableHeaderHasFocus] =
        useState<Record<keyof T extends string ? string : string, boolean>>();

    const [sorting, setSorting] = useState<{
        column: keyof T;
        type: SortingType;
    }>({
        column: defaultSortingColumn,
        type: 'desc',
    });

    const getDisplayValue = useCallback(
        (key: keyof T, item: T): string | number => {
            const headerVal = header[key];
            return typeof headerVal === 'string' ||
                (typeof headerVal === 'object' && !('get' in (headerVal as Header<T>)))
                ? (item[key] as string | number)
                : (headerVal as Header<T>)?.get(item);
        },
        [header],
    );

    const sortedBody = useMemo(
        () =>
            withoutSorting
                ? data
                : data?.toSorted((a, b) => {
                      const column = sorting.column as string;
                      const aValue = getDisplayValue(column as keyof T, a);
                      const bValue = getDisplayValue(column as keyof T, b);

                      let aX = aValue as string | number;
                      let bX = bValue as string | number;

                      if (typeof aX === 'string' && typeof bX === 'string') {
                          if (column.includes('date') || column.includes('time')) {
                              if (sorting.type === 'asc')
                                  return new Date(aX).getTime() - new Date(bX).getTime();
                              return new Date(bX).getTime() - new Date(aX).getTime();
                          }
                          if (sorting.type === 'asc')
                              return (aX || 'not available')?.localeCompare(bX || 'not available');
                          return (bX || 'not available')?.localeCompare(aX || 'not available');
                      }
                      aX = (aValue as number) ?? 0;
                      bX = (bValue as number) ?? 0;
                      if (typeof aX === 'number' && typeof bX === 'number') {
                          if (sorting.type === 'asc') return bX - aX;
                          return aX - bX;
                      }
                      return 1 - 0;
                  }),
        [data, getDisplayValue, withoutSorting, sorting.column, sorting.type],
    );

    const body = useMemo(
        () =>
            sortedBody.map((item, i) => (
                <Table.Tr
                    key={JSON.stringify(item)}
                    onClick={() => {
                        onRowClick({ index: i, rowData: item });
                    }}
                >
                    {Object.keys(header).map(key => {
                        const displayValue = getDisplayValue(key as keyof T, item);
                        return (
                            <Table.Td
                                // eslint-disable-next-line react/no-array-index-key
                                key={`row-${key}-${displayValue}-${i}`}
                            >
                                {displayValue}
                            </Table.Td>
                        );
                    })}
                </Table.Tr>
            )),
        [getDisplayValue, header, onRowClick, sortedBody],
    );

    const maxHeight =
        // ? 366 is the page - table space
        typeof rest.maxHeight === 'number' ? rest.maxHeight : MAX_HEIGHT;
    const rowHeight = paginationProps?.rowHeight ?? 35;
    const rowsPerPage = paginationProps?.rowsPerPage ?? Math.round(maxHeight / rowHeight);
    const total = Math.ceil(data.length / rowsPerPage);

    const paginatedBody = useMemo(() => {
        if (body.length <= rowsPerPage || withoutPagination || total === 1) return body;
        let from = (page - 1) * rowsPerPage;
        from = from <= 0 ? 0 : from;
        let to = page * rowsPerPage;
        to = to > body.length ? body.length : to;
        return [...body].slice(from, to);
    }, [page, rowsPerPage, body, withoutPagination, total]);

    return (
        <>
            <StyledTable
                {...rest}
                headers={Object.entries(header).map(([key, value]) => {
                    const v = value as Header<T>;
                    const displayValue =
                        typeof v === 'string' ? v : (v?.label ?? (v.value as string));
                    return (
                        <Table.Th key={displayValue}>
                            {withoutSorting || notSortableColumns?.includes(key as keyof T) ? (
                                displayValue
                            ) : (
                                <TableHeaderCell<keyof T>
                                    cell={key as keyof T}
                                    onClick={() => {
                                        setSorting({
                                            column: key as keyof T,
                                            type: handleCellClickType(
                                                (key === sorting.column && sorting.type) || false,
                                            ),
                                        });
                                    }}
                                    sorting={sorting}
                                    handleFocus={obj =>
                                        setTableHeaderHasFocus(prev => ({
                                            ...prev,
                                            ...obj,
                                        }))
                                    }
                                    focusBank={tableHeaderHasFocus}
                                    className="flex w-full items-center justify-center text-center"
                                >
                                    {displayValue}
                                </TableHeaderCell>
                            )}
                        </Table.Th>
                    );
                })}
                body={paginatedBody}
            />
            {!withoutPagination && total > 1 && (
                <Pagination
                    onChange={setPage}
                    page={page}
                    total={total}
                    className="flex w-full justify-center pt-3"
                />
            )}
        </>
    );
}

StyledTable.Simple = PreBuildStyledTable;
StyledTable.Tr = Table.Tr;
StyledTable.Tbody = Table.Tbody;
StyledTable.Td = Table.Td;
StyledTable.Tfoot = Table.Tfoot;
StyledTable.Thead = Table.Thead;
StyledTable.Th = Table.Th;

export default StyledTable;
