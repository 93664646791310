import React from 'react';
import classNames from 'classnames';
import { CellButton, SortingType } from './tableHeaderCellCarret';

function TableHeaderCell<T = string>({
    sorting,
    cell,
    children,
    onClick,
    focusBank,
    handleFocus,
    className,
    iconLeft,
}: {
    sorting: {
        column: T;
        type: SortingType;
    };
    cell: T;
    children: string | JSX.Element;
    iconLeft?: JSX.Element;
    onClick: (value: T) => void;
    handleFocus?: (value: Record<T extends string ? string : string, boolean>) => void;
    focusBank?: Record<T extends string ? string : string, boolean>;
    className?: string;
}) {
    return (
        <CellButton
            onClick={() => onClick(cell)}
            sortingType={sorting.column === cell ? sorting.type : false}
            className={classNames(className, 'flex justify-start items-center capitalize group', {
                'text-secondary': sorting.type && sorting.column === cell,
            })}
            iconLeft={iconLeft}
        >
            {children}
        </CellButton>
    );
}

export default TableHeaderCell;
